import React, { FC, useContext, useState } from 'react';
import { Formik } from 'formik';

import ModalV2 from '../modal-v2/modal-v2';
import { QuickAddType } from '../../../models/quick-add';
import { QuickAddMultiForm } from '../../../models/quick-add-multi-form';
import { BasketType } from '../../../models/basket-type';
import QuickAddMultiModalForm from './components/quick-add-multi-modal-form';
import { multiFormValidRowsCount } from '../../../helpers/quick-add';
import initialBasketValues from '../../providers/basket/persistence/initial';
import setMultiAddFormState from '../../providers/basket/actions/set-multi-add-form-state';
import {
  BasketDispatchContext,
  BasketStateContext,
} from '../../providers/basket/basket.provider';

interface Props extends QuickAddType {
  onSubmit: (values: QuickAddMultiForm) => Promise<BasketType>;
  closeModal: () => void;
}

const QuickAddMultiModal: FC<Props> = ({ labels, onSubmit, closeModal }) => {
  const basketState = useContext(BasketStateContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleFormSubmit = async (values) => {
    if (!(multiFormValidRowsCount(values) >= 1)) {
      return;
    }
    setIsSubmitting(true);
    const basketResult = await onSubmit(values);
    setIsSubmitting(false);
    basketDispatch(setMultiAddFormState(initialBasketValues.multiAddFormState));

    if (!basketResult?.lineItemIssues?.length) {
      // reOpenModal();
      closeModal();
    }
  };

  return (
    <ModalV2>
      <Formik
        initialValues={basketState.multiAddFormState}
        onSubmit={handleFormSubmit}
      >
        {({ values, setValues }) => {
          return (
            <QuickAddMultiModalForm
              values={values}
              isSubmitting={isSubmitting}
              setValues={setValues}
              closeModal={closeModal}
              labels={labels}
            />
          );
        }}
      </Formik>
    </ModalV2>
  );
};

export default QuickAddMultiModal;
