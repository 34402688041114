import React, { FC, useContext, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';

import FormText from '../../atoms/form-text/form-text';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';

import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import useIsMobile from '../../../hooks/use-is-mobile/use-is-mobile';

import { QuickAddType } from '../../../models/quick-add';
import { QuickAddSingleForm } from '../../../models/quick-add-single-form';
import { parseQuantityInput } from '../../../helpers/quick-add';
import categoryStructure from '../../../services/content-api/endpoints/category/structure';
import searchSku from '../../../services/search-api/endpoints/search/sku';
import useUser from '../../providers/user/hooks/use-user.hook';
import { ProductType } from '../../../models/product-type';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import QuickAddSearchModal from '../quick-add-search-modal/quick-add-search-modal';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import open from '../../providers/overlay/open.overlay.actions';
import close from '../../providers/overlay/close.overlay.actions';

const QuickAddSingle: FC<QuickAddType> = ({ labels, customerCode = false }) => {
  const isMobile = useIsMobile();
  const [isAddingToBasket, setIsAddingToBasket] = useState<boolean>(false);

  const addMultipleToBasket = useAddMultipleToBasket(
    {
      addProductErrorBody:
        labels?.addProductErrorBody ??
        '<p>All other items have been added to your basket. If you require further assistance please visit our support page or contact the support centre on 0845 456 15222</p>',
      addProductErrorDescription:
        labels?.addProductErrorDescription ??
        'Global>Quick Add Tool Description: {0}',
      addProductErrorHeading: labels?.addProductErrorHeading ?? 'Basket error',
      insufficientClearanceStockError:
        labels?.insufficientClearanceStockError ??
        'Not enough stock to add this product to the basket.',
    },
    {
      clearQuoteHeading: labels?.clearQuoteHeading || 'Clear Quote Heading',
      clearQuoteHelp: labels?.clearQuoteHelp || 'Clear Quote Help',
      clearQuoteDescription:
        labels?.clearQuoteDescription || 'Clear Quote Description',
      clearQuoteCancel: labels?.clearQuoteCancel || 'Cancel',
      clearQuoteAccept: labels?.clearQuoteAccept || 'Accept',
    }
  );

  const handleSubmit = async ({ sku, quantity }, { resetForm }) => {
    setIsAddingToBasket(true);

    await addMultipleToBasket([
      {
        variantSku: sku,
        quantity: parseQuantityInput(quantity),
      },
    ]);
    setIsAddingToBasket(false);
    resetForm();
  };

  const user = useUser();
  const overlayDispatch = useContext(OverlayDispatchContext);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const selectProduct = (
    products: ProductType[],
    onChooseProduct: (product: ProductType) => void
  ) => {
    overlayDispatch(
      open(
        <QuickAddSearchModal
          labels={{
            quickAddToolSearchHeadingLabel: 'Quick add search',
            quickAddToolSearchSelectButtonText: 'Select',
          }}
          products={products}
          onSubmit={(product) => {
            onChooseProduct(product);
            overlayDispatch(close());
          }}
        />
      )
    );

    pushToDataLayer(GtmEventNameEnum.QuickSearch);
  };

  const suggestProducts = async (
    sku: string,
    onChooseSku: (sku: string) => void
  ) => {
    if (!sku.length) {
      return;
    }

    const { catalogId } = await categoryStructure();

    const lineItems = await searchSku(
      catalogId,
      sku,
      user && customerCode ? user.accountNumber : null
    );

    selectProduct(
      lineItems.map((lineItem) => lineItem.product),
      (product) => onChooseSku(product.variantSku)
    );
  };
  return (
    <div className="w-full pb-4 sm:pb-0">
      <Formik initialValues={{ sku: '', quantity: '' }} onSubmit={handleSubmit}>
        {(formikProps: FormikProps<QuickAddSingleForm>) => {
          return (
            <Form>
              <div className="flex mb-1">
                <label className="w-3/5 font-semibold">
                  {labels?.singleAddTitle ?? 'Add single product'}
                </label>
                <p className="w-2/5 font-semibold">
                  {labels?.multiAddLabelQuantity ?? 'Quantity'}
                </p>
              </div>
              <div className="flex relative items-center space-x-2">
                <div className="w-3/5 flex relative">
                  <FormText
                    containerClassName="w-full"
                    inputClassName={`py-4 ${isAddingToBasket ? 'pr-16' : ''}`}
                    placeholder={labels.multiAddPlaceholderSearchField}
                    alwaysShowRing={false}
                    name="sku"
                    disabled={isAddingToBasket}
                    iconName="search"
                    iconPosition="right"
                    onIconClick={() =>
                      suggestProducts(formikProps.values?.sku, (sku) =>
                        formikProps.setFieldValue('sku', sku)
                      )
                    }
                  />
                  {isAddingToBasket && (
                    <div className="absolute flex items-center h-full right-5 top-0">
                      <LoadingSpinner className="-mb-2" />
                    </div>
                  )}
                </div>
                <div className="w-1/5">
                  <FormText
                    min={1}
                    name="quantity"
                    placeholder={labels.multiAddLabelQuantity}
                    required
                    step={1}
                    type="number"
                    disabled={isAddingToBasket}
                  />
                </div>
                <div className="w-1/5 pt-1 flex justify-center">
                  <ButtonPrimary
                    name={labels.labelAdd ?? 'Add'}
                    buttonType="submit"
                    paddingX={isMobile ? 'half' : 'full'}
                    disabled={isAddingToBasket || formikProps.values.sku === ''}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuickAddSingle;
