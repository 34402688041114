import React, { FC, useContext, useMemo, useRef } from 'react';
import { useField, useFormikContext } from 'formik';

import FormText from '../../../atoms/form-text/form-text';
import { QuickAddType } from '../../../../models/quick-add';
import IcomoonIcon from '../../../atoms/icomoon-icon/icomoon-icon';
import { BasketStateContext } from '../../../providers/basket/basket.provider';
import interpolateContent from '../../../../helpers/interpolate-content';
import RichText from '../../rich-text/rich-text';
import LoadingSpinner from '../../../atoms/loading-spinner/loading-spinner';
import categoryStructure from '../../../../services/content-api/endpoints/category/structure';
import searchSku from '../../../../services/search-api/endpoints/search/sku';
import useUser from '../../../providers/user/hooks/use-user.hook';
import { ProductType } from '../../../../models/product-type';
import { OverlayDispatchContext } from '../../../providers/overlay/overlay.provider';
import pop from '../../../providers/overlay/pop.overlay.actions';
import open from '../../../providers/overlay/open.overlay.actions';
import QuickAddSearchModal from '../../quick-add-search-modal/quick-add-search-modal';
import GtmEventNameEnum from '../../../../models/gtm-event-name-enum';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../../providers/data-layer/data-layer.provider';

interface Props extends QuickAddType {
  index: number;
  disabled: boolean;
  isLastRow: boolean;
  removeRow: (rowIndex: number) => void;
  onRowFilled: (rowIndex: number) => void;
  values: any;
}

const QuickAddMultiTableRow: FC<Props> = ({
  labels,
  index,
  disabled,
  isLastRow,
  removeRow,
  onRowFilled,
  values,
}) => {
  const fieldName = `items.${index}`;
  const [field] = useField(fieldName);
  const quantityRef = useRef(null);
  const basketState = useContext(BasketStateContext);
  const { setFieldValue } = useFormikContext(); // Get setFieldValue from useFormikContext

  const handleBlur = () => {
    if (field.value.text !== '') {
      onRowFilled(index);
    }
  };

  const removeRowDisabled = disabled || isLastRow;

  const handleDeleteItem = () => {
    removeRow(index);
  };

  const existingBasketCount = useMemo<number>(() => {
    if (field.value.data) {
      const foundItem = basketState.basket?.lineItems?.find(
        (basketItem) => basketItem.variantSku === field.value.data.skuVariant
      );
      return foundItem
        ? foundItem.quantitySatisfied +
            foundItem.quantitySatisfiedSlot1 +
            foundItem.quantitySatisfiedSlot2 +
            foundItem.quantitySatisfiedSlot3
        : 0;
    }
    return 0;
  }, [basketState, field.value]);

  const user = useUser();
  const overlayDispatch = useContext(OverlayDispatchContext);
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const customerCode = false;

  const selectProduct = (
    products: ProductType[],
    onChooseProduct: (product: ProductType) => void
  ) => {
    overlayDispatch(
      open(
        <QuickAddSearchModal
          labels={{
            quickAddToolSearchHeadingLabel: 'Quick add search',
            quickAddToolSearchSelectButtonText: 'Select',
          }}
          products={products}
          onSubmit={(product) => {
            onChooseProduct(product);
            overlayDispatch(pop());
          }}
        />,
        false,
        false,
        true,
        false,
        true
      )
    );

    pushToDataLayer(GtmEventNameEnum.QuickSearch);
  };

  const suggestProducts = async (
    sku: string,
    onChooseSku: (sku: string) => void
  ) => {
    if (!sku) {
      return;
    }

    const { catalogId } = await categoryStructure();

    const lineItems = await searchSku(
      catalogId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof sku === 'string' ? sku : sku?.text,
      user && customerCode ? user.accountNumber : null
    );

    selectProduct(
      lineItems.map((lineItem) => lineItem.product),
      (product) => onChooseSku(product.variantSku)
    );
  };
  return (
    <>
      <tr key={index}>
        <td>
          <div className="flex relative">
            {disabled && (
              <div className="absolute flex items-center h-full right-5 top-0">
                <LoadingSpinner className="-mb-2" />
              </div>
            )}
            <FormText
              name={`items.${index}.text`}
              backgroundColor="white"
              ringColor="grey-200"
              alwaysShowRing
              placeholder={labels?.multiAddPlaceholderSearchField ?? ''}
              placeholderColor="grey-200"
              containerClassName="w-full"
              inputClassName={`pl-3.5 pr-${disabled ? '16' : '3.5'}`}
              disabled={disabled}
              onBlur={handleBlur}
              iconName="search"
              iconPosition="right"
              onIconClick={() => {
                suggestProducts(values.items[index], (text) => {
                  setFieldValue(`items.${index}.text`, text);
                });
              }}
            />
          </div>
        </td>
        <td>
          <FormText
            type="number"
            name={`items.${index}.quantity`}
            backgroundColor="white"
            ringColor="grey-200"
            alwaysShowRing
            placeholder={labels.multiAddLabelQuantity}
            placeholderColor="grey-200"
            inputClassName="px-3.5"
            inputProps={{ ref: quantityRef }}
            disabled={disabled}
          />
        </td>
        <td>
          <div className="w-full h-full flex justify-center items-center">
            <button
              type="button"
              onClick={handleDeleteItem}
              disabled={removeRowDisabled}
              className={`${removeRowDisabled ? 'cursor-not-allowed' : ''}`}
            >
              <IcomoonIcon
                color={`grey-${isLastRow ? '200' : '400'}`}
                name="trash"
                size="xl"
              />
            </button>
          </div>
        </td>
      </tr>
      {!!existingBasketCount && (
        <tr>
          <td className="pl-3.5">
            <RichText>
              {interpolateContent(
                'Your basket already contains {0} x {1}',
                `<strong>${existingBasketCount}</strong>`,
                `<strong>${field.value.data.skuVariant}</strong>`
              )}
            </RichText>
          </td>
        </tr>
      )}
    </>
  );
};

export default QuickAddMultiTableRow;
