import {
  QuickAddMultiForm,
  QuickAddMultiFormRow,
} from '../models/quick-add-multi-form';

export const multiFormValidRows = (
  values: QuickAddMultiForm
): QuickAddMultiFormRow[] => values.items.filter((item) => item.text !== '');

export const multiFormValidRowsCount = (values: QuickAddMultiForm): number =>
  multiFormValidRows(values).length;

export const parseQuantityInput = (quantityInput: string): number => {
  const parsedQuantity = parseInt(quantityInput, 10);
  return !Number.isNaN(parsedQuantity) && parsedQuantity >= 1
    ? parsedQuantity
    : 1;
};
