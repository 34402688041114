import React, { FC, useContext, useEffect } from 'react';
import { FieldArray, Form } from 'formik';

import styles from './quick-add-multi-modal-form.module.scss';
import TableHeading from '../../../atoms/table-heading/table-heading';
import QuickAddMultiTableRow from './quick-add-multi-table-row';
import ButtonPrimary from '../../../atoms/button-primary/button-primary';
import { QuickAddType } from '../../../../models/quick-add';
import useIsMobile from '../../../../hooks/use-is-mobile/use-is-mobile';
import { QuickAddMultiForm } from '../../../../models/quick-add-multi-form';
import { multiFormValidRowsCount } from '../../../../helpers/quick-add';
import { BasketDispatchContext } from '../../../providers/basket/basket.provider';
import setMultiAddFormState from '../../../providers/basket/actions/set-multi-add-form-state';
import initialBasketValues from '../../../providers/basket/persistence/initial';
import BulkOrdering from '../../../organisms/bulk-ordering/bulk-ordering';
import { BulkOrderingType } from '../../../../models/bulk-ordering-type';

interface Props extends QuickAddType {
  values: QuickAddMultiForm;
  isSubmitting: boolean;
  setValues: (values: QuickAddMultiForm) => void;
  closeModal: () => void;
}

const QuickAddMultiModalForm: FC<Props> = ({
  labels,
  values,
  isSubmitting,
  setValues,
  closeModal,
}) => {
  const basketDispatch = useContext(BasketDispatchContext);
  const isMobile = useIsMobile();

  const resetForm = () => {
    setValues(initialBasketValues.multiAddFormState);
  };

  useEffect(() => {
    basketDispatch(setMultiAddFormState(values));
  }, [values, basketDispatch]);

  // TODO: fix everything here...
  const bulkOrderLabels = {
    ...labels,
    downloadTemplateLink:
      'https://dev-flowtech-media.uk.deptagency.com/media/1j5h2wpx/bulk-ordering-template.csv',
    downloadTemplateLinkText: 'Download Template',
    uploadCsvButtonText: 'Upload .csv',
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row flex-nowrap justify-between">
        <p className="font-semibold mb-2">{labels.multiAddTitle}</p>
        <button
          type="button"
          className="text-french-blue font-semibold"
          onClick={resetForm}
        >
          Clear all
        </button>
      </div>
      {!isMobile && (
        <div className="py-6">
          <BulkOrdering
            {...(bulkOrderLabels as unknown as BulkOrderingType)}
            onUploadComplete={closeModal}
            compact
          />
        </div>
      )}
      <Form>
        <div className={styles.container}>
          <table className={styles.table}>
            <thead>
              <tr>
                <TableHeading
                  label={
                    isMobile
                      ? 'Code'
                      : labels.multiAddLabelProductManufacturerCode
                  }
                />
                <TableHeading
                  label={labels.multiAddLabelQuantity}
                  className="w-28 lg:w-32"
                />
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="w-6" />
              </tr>
            </thead>
            <tbody>
              <FieldArray name="items">
                {(arrayHelpers) => {
                  return (
                    values?.items?.length &&
                    values.items.map((item, index) => (
                      <QuickAddMultiTableRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={`table-row-${index}`}
                        labels={labels}
                        index={index}
                        disabled={isSubmitting}
                        values={values}
                        isLastRow={values?.items?.length < 2}
                        removeRow={arrayHelpers.remove}
                        onRowFilled={(rowIndex: number) => {
                          // add empty row if all other rows are filled
                          const rows = arrayHelpers.form.values?.items ?? [];
                          const otherRows = [...rows];
                          otherRows.splice(rowIndex, 1);
                          const emptyRows = otherRows.filter(
                            (row) => row.text === ''
                          ).length;

                          if (emptyRows === 0) {
                            arrayHelpers.push({
                              text: '',
                              quantity: '',
                            });
                          }
                        }}
                      />
                    ))
                  );
                }}
              </FieldArray>
            </tbody>
          </table>
        </div>
        <div className="mt-6 pb-8">
          <ButtonPrimary
            buttonType="submit"
            name={labels.mulitAddLabelAddToBasket}
            disabled={isSubmitting || multiFormValidRowsCount(values) < 1}
          />
        </div>
      </Form>
    </div>
  );
};

export default QuickAddMultiModalForm;
