import { motion } from 'framer-motion';
import React, { FC, ReactNode, useContext, Fragment } from 'react';
import getTailwindConfig from '../../../helpers/get-tailwind-config';
import useWindowSize from '../../../hooks/use-window-size/use-window-size';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import styles from './modal-v2.module.scss';

interface Props {
  readonly alert?: boolean;
  readonly children: ReactNode;
  readonly medium?: boolean;
  readonly mobileMenu?: boolean;
  readonly onClose?: () => void;
}

const ModalV2: FC<Props> = ({
  alert,
  children,
  medium,
  mobileMenu,
  onClose,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const { width: windowWidth } = useWindowSize();
  const { screens } = getTailwindConfig().theme;
  const isMobile = windowWidth <= parseInt(screens.sm, 10) || mobileMenu;
  const initial = isMobile
    ? { opacity: 1, y: mobileMenu ? 0 : 60, x: mobileMenu ? '-100%' : 0 }
    : { height: '0%', opacity: 1, width: '0%' };

  let containerStyle = styles.outer;
  let height = '90%';
  if (mobileMenu) {
    containerStyle = styles['mobile-menu'];
  } else if (alert) {
    containerStyle = styles.alert;
    height = '266px';
  } else if (medium) {
    containerStyle = styles.medium;
    height = '460px';
  }

  const animate = isMobile
    ? { opacity: 1, y: 0, x: 0 }
    : { height, opacity: 1, width: '100%' };

  return (
    <Fragment key={String(isMobile)}>
      {windowWidth && (
        <motion.div
          animate={animate}
          className={containerStyle}
          initial={initial}
          transition={{
            damping: 24,
            duration: 0.6,
            mass: 0.8,
            stiffness: 200,
            type: 'spring',
          }}
        >
          <div className="md:absolute bg-white min-h-full inset-0 m-auto origin-center md:rounded-lg">
            <motion.div
              animate={{ opacity: 1 }}
              className="h-screen md:h-full"
              initial={{ opacity: 0 }}
              transition={{
                delay: 0.35,
                duration: 0.3,
              }}
            >
              <div className="flex items-center justify-end p-2 md:p-3">
                <IcomoonIconButton
                  color="french-blue"
                  name="close"
                  onClick={() => {
                    overlayDispatch(close());

                    onClose();
                  }}
                  size="lg"
                />
              </div>

              <div className={`${styles.inner} flex flex-col justify-center`}>
                {children}
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </Fragment>
  );
};

ModalV2.defaultProps = {
  alert: false,
  medium: false,
  mobileMenu: false,
  onClose: () => ({}),
};

export default ModalV2;
