import React, { FC, useContext } from 'react';

import ButtonSecondary from '../../atoms/button-secondary/button-secondary';
import QuickAddMultiModal from '../quick-add-multi-modal/quick-add-multi-modal';

import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import open from '../../providers/overlay/open.overlay.actions';
import close from '../../providers/overlay/close.overlay.actions';
import useAddMultipleToBasket from '../../providers/basket/hooks/use-add-multiple-to-basket';
import {
  multiFormValidRows,
  parseQuantityInput,
} from '../../../helpers/quick-add';

import { QuickAddType } from '../../../models/quick-add';
import { QuickAddMultiForm } from '../../../models/quick-add-multi-form';

const QuickAddMulti: FC<QuickAddType> = ({ labels }) => {
  const overlayDispatch = useContext(OverlayDispatchContext);
  const addMultipleToBasket = useAddMultipleToBasket(
    {
      addProductErrorBody:
        labels?.addProductErrorBody ??
        '<p>All other items have been added to your basket. If you require further assistance please visit our support page or contact the support centre on 0845 456 15222</p>',
      addProductErrorDescription:
        labels?.addProductErrorDescription ??
        'Global>Quick Add Tool Description: {0}',
      addProductErrorHeading: labels?.addProductErrorHeading ?? 'Basket error',
      insufficientClearanceStockError:
        labels?.insufficientClearanceStockError ??
        'Not enough stock to add this product to the basket.',
    },
    {
      clearQuoteHeading: labels?.clearQuoteHeading || 'Clear Quote Heading',
      clearQuoteHelp: labels?.clearQuoteHelp || 'Clear Quote Help',
      clearQuoteDescription:
        labels?.clearQuoteDescription || 'Clear Quote Description',
      clearQuoteCancel: labels?.clearQuoteCancel || 'Cancel',
      clearQuoteAccept: labels?.clearQuoteAccept || 'Accept',
    }
  );

  const handleSubmit = async (values: QuickAddMultiForm) => {
    const validRows = multiFormValidRows(values);

    return addMultipleToBasket(
      validRows.map((item) => ({
        variantSku: item.text,
        quantity: parseQuantityInput(item.quantity),
      }))
    );
  };

  const handleModalClose = () => overlayDispatch(close());

  const handleModalOpen = () => {
    overlayDispatch(
      open(
        <QuickAddMultiModal
          key="quick-add-multi-modal"
          labels={labels}
          onSubmit={handleSubmit}
          closeModal={handleModalClose}
        />,
        false,
        false,
        true,
        false,
        true
      )
    );
  };

  return (
    <div className="pt-1">
      <ButtonSecondary
        name={labels.multiAddTitle}
        onClick={handleModalOpen}
        paddingX="half"
      />
    </div>
  );
};

export default QuickAddMulti;
