import BasketActions from '.';
import Action from '../../../../types/action';
import { QuickAddMultiForm } from '../../../../models/quick-add-multi-form';

const setMultiAddFormState = (
  values: QuickAddMultiForm
): Action<BasketActions> => {
  return {
    type: BasketActions.SetMultiAddFormState,
    payload: values,
  };
};

export default setMultiAddFormState;
