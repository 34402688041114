import { Form, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { QuickAddToolType } from '../../../models/quick-add-tool';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import Modal from '../modal/modal';
import styles from './quick-add-search-modal.module.scss';
import { ProductType } from '../../../models/product-type';

interface SingleQuickAddModalProps {
  readonly quickAddToolSearchHeadingLabel: string;
  readonly quickAddToolSearchSelectButtonText: string;
}
interface Props {
  readonly labels: QuickAddToolType['labels'] | SingleQuickAddModalProps;
  readonly products: ProductType[];
  readonly onSubmit: (product: ProductType) => void;
}

const QuickAddSearchModal: FC<Props> = ({
  labels,
  products,
  onSubmit,
}: Props) => {
  const [selected, setSelected] = useState<ProductType>();

  return (
    <Modal>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          if (selected) onSubmit(selected);
        }}
      >
        <Form>
          <h3 className="page-heading-three mb-6">
            {labels.quickAddToolSearchHeadingLabel}
          </h3>

          <div className="border-2 border-grey-100 flex flex-col h-96 items-start p-4 rounded-md">
            <div className="custom-scrollbar-primary overflow-y-auto w-full">
              {products.map((product) => (
                <button
                  key={product.variantSku}
                  className={`${styles.button} ${
                    selected?.variantSku === product.variantSku
                      ? 'bg-french-blue outline-none text-white'
                      : ''
                  }`}
                  onClick={() => setSelected(product)}
                  onFocus={() => setSelected(product)}
                  type="button"
                >
                  {product.variantSku} - {product.name}
                </button>
              ))}
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonPrimary
              className="mt-6"
              disabled={!selected}
              name={labels.quickAddToolSearchSelectButtonText}
              buttonType="submit"
            />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default QuickAddSearchModal;
