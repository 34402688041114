import CultureCodeEnum from '../../../../models/culture-code-enum';
import { ProductLineItemType } from '../../../../models/product-line-item-type';
import client from '../../client-unauthenticated';

const searchSku = async (
  catalogId: string,
  query: string,
  accountNumber?: number
): Promise<ProductLineItemType[]> => {
  const { data } = await client.post<{ results: ProductLineItemType[] }>(
    '/search/sku',
    {
      accountNumber,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      cultureCode: CultureCodeEnum.EnGb,
      pageSize: 30,
      catalogId,
      query,
    }
  );

  return data.results;
};

export default searchSku;
