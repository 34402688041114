import { useMemo } from 'react';
import useWindowSize from '../use-window-size/use-window-size';
import getTailwindConfig from '../../helpers/get-tailwind-config';

const useIsMobile = (): boolean => {
  const { width } = useWindowSize();
  const { screens } = getTailwindConfig().theme;
  const isMobile = useMemo(() => width <= parseInt(screens.sm, 10), [width]);

  return isMobile;
};

export default useIsMobile;
