import React, { FC } from 'react';

import { QuickAddType } from '../../../models/quick-add';
import QuickAddMulti from '../../molecules/quick-add-multi/quick-add-multi';
import useIsMobile from '../../../hooks/use-is-mobile/use-is-mobile';
import QuickAddSingle from '../../molecules/quick-add-single/quick-add-single';

const QuickAdd: FC<QuickAddType> = ({ labels, customerCode }) => {
  const isMobile = useIsMobile();

  return (
    <div className="w-full py-4 border-b border-grey-100 flex flex-col items-center lg:flex-row lg:flex-nowrap lg:items-end">
      <QuickAddSingle labels={labels} customerCode={customerCode} />
      {!isMobile && <div className="py-4 px-6 font-bold text-grey-200">or</div>}
      <QuickAddMulti labels={labels} customerCode={customerCode} />
    </div>
  );
};

export default QuickAdd;
